import Step from "../../components/step/Step";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import useStore from "../../storage/DataStore";
import { useNavigate } from "react-router-dom";

function Step3() {
    const intl = useIntl()
    const navigate = useNavigate()

    const UserDataSchema = Yup.object().shape({
        weight: Yup.number()
            .max('300', intl.formatMessage({ id: 'survey.step3.form.weight.required' }))
            .required(intl.formatMessage({ id: 'survey.step3.form.weight.required' }))
            .typeError(intl.formatMessage({ id: 'survey.step3.form.isNumber' })),
        targetWeight: Yup.number()
            .max('300', intl.formatMessage({ id: 'survey.step3.form.targetWeight.required' }))
            .required(intl.formatMessage({ id: 'survey.step3.form.targetWeight.required' }))
            .typeError(intl.formatMessage({ id: 'survey.step3.form.isNumber' })),
        bodyHeight: Yup.number()
            .required(intl.formatMessage({ id: 'survey.step3.form.bodyHeight.required' }))
            .typeError(intl.formatMessage({ id: 'survey.step3.form.isNumber' }))
    });

    return (
        <Step currentStep={3}
            heading={<FormattedMessage id={'survey.step3.headline'} />}
            description={<FormattedMessage id={'survey.step3.description'} />}
            StepImage="https://source.unsplash.com/bwFW9PTJZx8"
            // StepVideo="https://iframe.videodelivery.net/10f42172f6f01db0c604528651e79144?preload=true"
            backLink={'/step2'}>
            <Formik
                initialValues={{
                    weight: useStore.getState().weight !== 0 ? useStore.getState().weight : '',
                    targetWeight: useStore.getState().targetWeight !== 0 ? useStore.getState().targetWeight : '',
                    bodyHeight: useStore.getState().bodyHeight !== 0 ? useStore.getState().bodyHeight : '',
                }}
                validationSchema={UserDataSchema}
                onSubmit={(values, actions) => {
                    useStore.setState({
                        weight: values.weight,
                        targetWeight: values.targetWeight,
                        bodyHeight: values.bodyHeight
                    });

                    navigate('../step4', { replace: true });
                }}
            >
                <Form>
                    <div className={"grid grid-cols-2 gap-4"}>
                        <div>
                            <label className={"block mb-2 font-bold"} htmlFor="weight">
                                <FormattedMessage id={"survey.step3.form.weight"} />
                            </label>
                            <Field name="weight">
                                {({ field, form }) => (
                                    <>
                                        <div class="flex flex-wrap items-stretch w-full relative">
                                            <input pattern={'[0-9]*'}
                                                inputMode={'number'} {...field}
                                                id="weight"
                                                className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 leading-tight border h-10 border-grey-light rounded rounded-r-none px-3 relative focus:outline-none focus:shadow-outline" />
                                            <div class="flex -mr-px">
                                                <span class="flex items-center leading-normal bg-grey-lighter rounded rounded-l-none border border-l-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-sm">KG</span>
                                            </div>
                                        </div>
                                        <p className={"py-2 text-sm text-danger"}>{form.errors.weight}</p>
                                    </>
                                )}
                            </Field>

                        </div>

                        <div>
                            <label className={"block mb-2 font-bold"} htmlFor="targetWeight">
                                <FormattedMessage id={"survey.step3.form.targetWeight"} />
                            </label>
                            <Field name="targetWeight">
                                {({ field, form }) => (
                                    <>
                                        <div class="flex flex-wrap items-stretch w-full relative">
                                            <input pattern={'[0-9]*'}
                                                inputMode={'number'} {...field}
                                                id="targetWeight"
                                                className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 leading-tight border h-10 border-grey-light rounded rounded-r-none px-3 relative focus:outline-none focus:shadow-outline" />
                                            <div class="flex -mr-px">
                                                <span class="flex items-center leading-normal bg-grey-lighter rounded rounded-l-none border border-l-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-sm">KG</span>
                                            </div>
                                        </div>

                                        <p className={"py-2 text-sm text-danger"}>{form.errors.targetWeight}</p>
                                    </>
                                )}
                            </Field>
                        </div>
                    </div>

                    <div className={"mt-4"}>
                        <label className={"block mb-2 font-bold"} htmlFor="bodyHeight">
                            <FormattedMessage id={"survey.step3.form.bodyHeight"} />
                        </label>
                        <Field name="bodyHeight">
                            {({ field, form }) => (
                                <>
                                    <div class="flex flex-wrap items-stretch w-full relative">
                                        <input pattern={'[0-9]*'}
                                            inputMode={'number'} {...field}
                                            id="bodyHeight"
                                            className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 leading-tight border h-10 border-grey-light rounded rounded-r-none px-3 relative focus:outline-none focus:shadow-outline" />
                                        <div class="flex -mr-px">
                                            <span class="flex items-center leading-normal bg-grey-lighter rounded rounded-l-none border border-l-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-sm">CM</span>
                                        </div>
                                    </div>

                                    <p className={"py-2 text-sm text-danger"}>{form.errors.bodyHeight}</p>
                                </>
                            )}
                        </Field>
                    </div>
                    <div className="fixed sm:relative bottom-0 left-0 w-full p-4 sm:p-0 mt-6">
                        <button type="submit"
                            className={"w-full block bg-primary text-white text-center rounded-lg shadow-sm px-6 py-4 cursor-pointer hover:ring-2 hover:ring-primary hover:border-primary focus:outline-none"}>
                            <FormattedMessage id={'button.next'} />
                        </button>
                    </div>
                </Form>
            </Formik>
        </Step>
    );
}

export default Step3;
