import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import styles from "../../styles/custom.module.css"

function StepProgress(props) {
    return (
        <div className="mb-5">
            <progress
                className={`step-progress-bar w-full ${styles.stepProgressPar}`}
                value={props.currentStep}
                max={props.maxSteps}
            ></progress>

            <div className="flex flex-row items-center justify-between mb-2">
                {props.backLink &&
                    <Link to={props.backLink} className="pl-0 inline-flex items-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                        <FormattedMessage id={"button.back"} />
                    </Link>
                }
                {props.currentStep}/{props.maxSteps}
            </div>
        </div>
    )
}

export default StepProgress;
