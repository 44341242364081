function InfoGraph(props) {
    return (
       <>
           <svg
               className="w-full h-auto"
               width="1000"
               height="500"
               viewBox="0 0 1000 500"
               xmlns="http://www.w3.org/2000/svg"
           >
               <g className="graph-date">
                   <text transform="translate(65.116 30.875)" fontSize="28">
                       <tspan x="-36.171" y="0">
                           {props.currentWeight} kg
                       </tspan>
                   </text>
               </g>
               <g className="graph-date">
                   <text transform="translate(925.116 340.875)" fontSize="28">
                       <tspan x="-36.171" y="0">
                           {props.targetWeight} kg
                       </tspan>
                   </text>
               </g>
               <path
                   d="M 0,49.81064281404734 C 50,63.47293816527332 150,81.46265533902653 250,118.12211957017723 C 350,154.78158380132794 400,183.41508158965388 500,233.10796396980095 C 600,282.800846349948 650,332.89362096167406 750,366.5865314709125 C 850,400.279441980151 950,394.5753195069771 1000,401.57251651599324,L 1000 500,L 0 500Z"
                   fill="#ee5ca82b"
               />
               <path
                   d="M 0,49.81064281404734 C 50,63.47293816527332 150,81.46265533902653 250,118.12211957017723 C 350,154.78158380132794 400,183.41508158965388 500,233.10796396980095 C 600,282.800846349948 650,332.89362096167406 750,366.5865314709125 C 850,400.279441980151 950,394.5753195069771 1000,401.57251651599324"
                   fill="none"
                   stroke="#ee5da8"
                   strokeWidth="4px"
               />
               <g>
                   <circle cx="0" cy="49.81064281404734" r="8" fill="#ee5da8" />
                   <circle cx="250" cy="118.12211957017723" r="8" fill="#ee5da8" />
                   <circle cx="500" cy="233.10796396980095" r="8" fill="#ee5da8" />
                   <circle cx="750" cy="366.5865314709125" r="8" fill="#ee5da8" />
                   <circle cx="1000" cy="401.57251651599324" r="8" fill="#ee5da8" />
               </g>
           </svg>
       </>
    )
}

export default InfoGraph;
