import Step from "../../components/step/Step";
import { FormattedMessage } from "react-intl";
import BodySelector from "../../components/body-selector/BodySelector";
import { Link } from "react-router-dom";
import useStore from "../../storage/DataStore";

function Step2() {
    const state = useStore.getState();

    return (
        <Step currentStep={2}
            heading={<FormattedMessage id={'survey.step2.headline'} />}
            description={<FormattedMessage id={'survey.step2.description'} />}
            backLink={'/step1'}>

            <BodySelector gender={state.gender} />

            <div className="fixed sm:relative bottom-0 left-0 w-full p-4 sm:p-0 mt-6">
                <Link to={"/step3"}
                    className={"w-full block bg-primary text-white text-center border rounded-lg shadow-sm px-6 py-4 cursor-pointer hover:ring-2 hover:ring-primary hover:border-primary focus:outline-none"}>
                    <FormattedMessage id={'button.next'} />
                </Link>
            </div>
        </Step>
    );
}

export default Step2;
