import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'react-circular-progressbar/dist/styles.css';

function Loader() {
    let [percentage, setPercentage] = useState(0);
    let [text, setText] = useState('Bitte warten...');

    let navigate = useNavigate();

    useEffect(() => {
        if (percentage > 60) {
            setText('Ihr voraussichtlicher Gewichtsverlust wird berechnet');
        }

        if (percentage === 100) {
            alert('redirect to offer');
        }

        if (percentage < 100) {
            const interval = setInterval(() => {
                setPercentage(percentage => percentage + 10);
            }, 300);
            return () => clearInterval(interval);
        }
    }, [percentage, navigate]);

    return (
        <div className={"z-50"}>
            <div className="absolute left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                <div className={"flex justify-center items-center"}>
                    <div className={"w-[200px] h-[200px]"}>
                        <CircularProgressbar
                            value={percentage}
                            text={percentage + "%"}
                            styles={buildStyles({
                                pathColor: `rgba(238, 93, 168, ${percentage / 100})`,
                                textColor: "#ee5da8",
                                strokeLinecap: 'round'
                            })}
                        />
                    </div>
                </div>

                <p className={"mt-5 text-3xl font-bold text-center"}>{text}</p>
            </div>
        </div>
    );
}

export default Loader;
