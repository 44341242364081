import Logo from '../assets/logo.svg';

function DefaultTemplate(props) {
    return (
        <div className={"text-primary h-full"}>
            <header className={"bg-primary h-[65px]"}>
                <div className={"container mx-auto p-2 text-center flex items-center justify-center h-full"}>
                    <img src={Logo} alt={"Liba Logo"} className={"inline w-[30px] md:w-[40px]"} />
                </div>
            </header>

            <main style={{ height: "calc(100% - 65px)" }}>
                {props.children}
            </main>
        </div>
    )
}

export default DefaultTemplate;
