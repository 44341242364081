import create from 'zustand'

const useStore = create(set => ({
    gender: '',
    weight: 0,
    targetWeight: 0,
    bodyHeight: 0,
    age: 0
}));

export default useStore;
