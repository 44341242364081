import Step from "../../components/step/Step";
import { FormattedMessage } from "react-intl";
import useStore from "../../storage/DataStore";
import InfoGraph from "../../components/info-graph/InfoGraph";
import { Link } from "react-router-dom";

function Step4() {
    const state = useStore.getState();

    return (
        <Step currentStep={4}
            heading={<FormattedMessage id={'survey.step4.headline'} />}
            description={<FormattedMessage id={'survey.step4.description'} />}
            backLink={'/step3'}>

            <div className="mb-5">
                <InfoGraph currentWeight={state.weight} targetWeight={state.targetWeight} />
            </div>
            <div className="fixed sm:relative bottom-0 left-0 w-full p-4 sm:p-0 mt-6">
                <Link to={"/loader"}
                    className={"w-full block bg-primary text-white text-center border rounded-lg shadow-sm px-6 py-4 cursor-pointer hover:ring-2 hover:ring-primary hover:border-primary focus:outline-none"}>
                    <FormattedMessage id={'button.next'} />
                </Link>
            </div>
        </Step>
    );
}

export default Step4;
