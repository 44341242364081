import Male01 from '../../assets/body-shapes/male_01.png';
import Male02 from '../../assets/body-shapes/male_02.png';
import Male03 from '../../assets/body-shapes/male_03.png';
import Male04 from '../../assets/body-shapes/male_04.png';
import Male05 from '../../assets/body-shapes/male_05.png';
import Female01 from '../../assets/body-shapes/female_01.png';
import Female02 from '../../assets/body-shapes/female_02.png';
import Female03 from '../../assets/body-shapes/female_03.png';
import Female04 from '../../assets/body-shapes/female_04.png';
import Female05 from '../../assets/body-shapes/female_05.png';
import './BodySelector.css';
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Range } from "react-range";

function BodySelector(props) {
    const [imageIndex, setImageIndex] = useState([2]);
    const [indicator, setIndicator] = useState(true);

    const updateIndex = function (element) {
        setImageIndex(element);
        setIndicator(false)
    }

    return (
        <>
            <Range min={1}
                max={5}
                onChange={updateIndex}
                values={[imageIndex]}
                renderTrack={({ props, children }) => (
                    <div className='range-slider-wrapper py-6'>
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '6px',
                                width: '100%',
                                backgroundColor: '#ccc',
                                marginBottom: '2rem'
                            }}
                        >
                            {children}
                        </div>
                    </div>
                )}
                renderThumb={({ props }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: '30px',
                            width: '30px',
                            borderRadius: '50%',
                            backgroundColor: '#142b6f'
                        }}
                    >
                        {indicator ? <svg className="range-indicator absolute w-[30px] left-1.5 top-9 fill-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 363.85 400.79">
                            <path className="cls-1" d="M471.14 244a40.44 40.44 0 0 0-40.38-39.75h-10a6 6 0 0 0-2.84.72 40.31 40.31 0 0 0-31.41-15h-10a6.19 6.19 0 0 0-2.9.75 50.42 50.42 0 0 0-35.2-21.24V108a28.29 28.29 0 0 0-56.57 0v146.85l-17.92-17.93a28.29 28.29 0 0 0-40.56 39.44c4.21 7 46 78.2 55.1 147.69a6.15 6.15 0 0 0 6.1 5.35h152.88a6.14 6.14 0 0 0 5.92-4.49c.2-.69 19.33-68.94 27.61-102.05 8.19-32.79 1.13-73.66.17-78.86zM459 319.88c-7 28-21.86 81.43-26.26 97.21H289.87c-11.24-73.55-54.52-144.67-56.4-147.74a6.07 6.07 0 0 0-.89-1.13 16 16 0 0 1 22.59-22.59l28.28 28.28a6.12 6.12 0 0 0 3.35 1.87 5 5 0 0 0 .54.06c.21 0 .41.06.62.06a6.16 6.16 0 0 0 4.37-1.81 6.09 6.09 0 0 0 1.77-4.66V108a16 16 0 0 1 31.95 0v136.63a6.16 6.16 0 1 0 12.31 0v-62.75a38.17 38.17 0 0 1 31.95 37.6v25.15a6.16 6.16 0 1 0 12.31 0v-42.37h3.86a28.12 28.12 0 0 1 28.09 28.08v14.3a6.16 6.16 0 1 0 12.31 0v-28.08h3.87a28.12 28.12 0 0 1 28.08 28.08 7.17 7.17 0 0 0 .08.81v.36a.64.64 0 0 0 0 .07c.44 2.02 7.89 42.9.09 74zM132.05 66.48h129.87l-15.05 15a6.16 6.16 0 1 0 8.71 8.7l25.53-25.53a5.88 5.88 0 0 0 .78-.95s0-.08.06-.11a5.41 5.41 0 0 0 .51-1c0-.1.06-.21.1-.31a7.93 7.93 0 0 0 .25-.83 6.26 6.26 0 0 0 0-2.44 7.27 7.27 0 0 0-.25-.83c0-.1-.06-.21-.1-.31a5.41 5.41 0 0 0-.51-1s0-.07-.06-.11a5.88 5.88 0 0 0-.78-.95l-25.53-25.39a6.16 6.16 0 0 0-8.71 8.71l15.05 15H132.05l15-15a6.16 6.16 0 1 0-8.71-8.71L112.85 56a6.46 6.46 0 0 0-.78.95.39.39 0 0 1-.07.05 6.12 6.12 0 0 0-.5 1c0 .1-.06.21-.1.31a7.27 7.27 0 0 0-.25.83 6.26 6.26 0 0 0 0 2.44 6.55 6.55 0 0 0 .25.83c0 .1.06.21.1.31a6.12 6.12 0 0 0 .5 1 .39.39 0 0 1 .06.11 6.46 6.46 0 0 0 .78.95l25.54 25.53a6.16 6.16 0 1 0 8.71-8.7z" transform="translate(-111.04 -28.62)" />
                        </svg> : null}
                    </div>
                )}
            />

            <div className={"body-selector-image mb-8"}>
                {props.gender === 'male' &&
                    <>
                        <img style={{ opacity: imageIndex[0] === 1 ? 1 : 0 }} src={Male01} alt="" />
                        <img style={{ opacity: imageIndex[0] === 2 ? 1 : 0 }} src={Male02} alt="" />
                        <img style={{ opacity: imageIndex[0] === 3 ? 1 : 0 }} src={Male03} alt="" />
                        <img style={{ opacity: imageIndex[0] === 4 ? 1 : 0 }} src={Male04} alt="" />
                        <img style={{ opacity: imageIndex[0] === 5 ? 1 : 0 }} src={Male05} alt="" />
                    </>
                }
                {props.gender === 'female' &&
                    <>
                        <img style={{ opacity: imageIndex[0] === 1 ? 1 : 0 }} src={Female01} alt="" />
                        <img style={{ opacity: imageIndex[0] === 2 ? 1 : 0 }} src={Female02} alt="" />
                        <img style={{ opacity: imageIndex[0] === 3 ? 1 : 0 }} src={Female03} alt="" />
                        <img style={{ opacity: imageIndex[0] === 4 ? 1 : 0 }} src={Female04} alt="" />
                        <img style={{ opacity: imageIndex[0] === 5 ? 1 : 0 }} src={Female05} alt="" />
                    </>
                }
            </div>

            <div className={"text-center font-bold text-2xl mt-2 mb-2"}>
                <FormattedMessage id={'survey.bodySelector.' + imageIndex} />
            </div>
        </>
    )
}

export default BodySelector;
