import { MemoryRouter, Route, Routes } from "react-router-dom";

import German from './lang/de.json';
import { IntlProvider } from "react-intl";
import DefaultTemplate from "./template/DefaultTemplate";
import Home from "./pages/home/Home";
import Step1 from "./pages/step1/Step1";
import Step2 from "./pages/step2/Step2";
import Step3 from "./pages/step3/Step3";
import Step4 from "./pages/step4/Step4";
import Loader from "./pages/loader/Loader";

function App() {
    return (<div className={'page text-primary h-full'}>
        <MemoryRouter>
            <IntlProvider locale={'de'} messages={German} defaultLocale="de">
                <DefaultTemplate>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/step1' element={<Step1 />} />
                        <Route path='/step2' element={<Step2 />} />
                        <Route path='/step3' element={<Step3 />} />
                        <Route path='/step4' element={<Step4 />} />
                        <Route path='/loader' element={<Loader />} />
                    </Routes>
                </DefaultTemplate>
            </IntlProvider>
        </MemoryRouter>
    </div>);
}

export default App;
