import StepProgress from "../step-progress/StepProgress";

function Step(props) {
    return (
        <div className="h-full flex flex-col">
            <div className={"w-full mx-auto max-w-xl sm:max-w-lg px-4 sm:px-0 pt-8 pb-8"}>
                <StepProgress currentStep={props.currentStep}
                    backLink={props.backLink}
                    maxSteps="5" />
            </div>
            {props.StepImage || props.StepVideo ?
                <div class="h-full flex justify-center items-center w-full w-full">
                    <div className="w-full relative md:flex flex-row mb-24 bg-gray">
                        <div className="w-full md:w-2/4 text-center justify-center min-h-[300px] md:min-h-[500px]">
                            {props.StepImage ? <img src={props.StepImage} alt="" className="max-w-full align-middle object-cover h-full" /> : ""}
                            {props.StepVideo ?
                                <iframe
                                    src={props.StepVideo}
                                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                                    title={"Video"}
                                    className={"h-full w-full min-h-[300px] md:min-h-[500px]"}
                                    allowFullScreen={true}></iframe>
                                : ""}
                        </div>
                        <div className="w-full md:w-2/4 text-center md:text-left px-0 py-8 sm:px-8 sm:py-8 sm: flex flex-col justify-center min-h-[300px] md:min-h-[500px]">
                            <div className={"mx-auto max-w-sm w-full px-4 sm:px-0 pb-20 sm:pb-0"}>
                                <div className={"text-center mb-8"}>
                                    <h2 className={"font-bold leading-tight text-3xl"}>{props.heading}</h2>
                                    {props.description && <p className={"mt-2"}>{props.description}</p>}
                                </div>

                                <div>
                                    {props.children}
                                </div>
                            </div>

                        </div>
                    </div>
                </div> :
                <div className={"mx-auto max-w-xl sm:max-w-lg px-4 sm:px-0 pb-20 sm:pb-0 w-full"}>
                    <div className="min-h-[400px] px-4 lg:px-12 py-4 md:py-6 flex flex-col justify-between">
                        <div className={"text-center mb-8"}>
                            <h2 className={"font-bold leading-tight text-3xl"}>{props.heading}</h2>
                            {props.description && <p className={"mt-2"}>{props.description}</p>}
                        </div>

                        <div>
                            {props.children}
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}

export default Step;
