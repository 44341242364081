import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Hero from '../../assets/hero-banner.jpg';
import Reviews from "../../components/reviews";

function Home() {
    return (
        <>
            <div className={"text-center"}>
                <div style={{ backgroundImage: `url(${Hero})` }} className={"bg-center bg-cover py-10 md:py-24"}>
                    <div className="mx-auto max-w-xs md:max-w-lg md:text-center">
                        <h1 className="text-4xl tracking-tight font-normal text-gray-900 md:text-5xl lg:text-6xl"><FormattedMessage id={'home.headline'} /></h1>
                        <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl"><FormattedMessage id={'home.text'} /></p>

                        <Link className={"bg-primary text-white p-10 py-3 mt-8 rounded-lg inline-block hover:bg-primary-200"}
                            to={'step1'}>
                            <FormattedMessage id={'home.button.start'} />
                        </Link>
                    </div>
                </div>
            </div>
            <Reviews />
        </>
    );
}

export default Home;
