import Avatar from "../../assets/avatar.png"

const reviews = [
    {
        content: 'TaxPal is so easy to use I can’t help but wonder if it’s really doing the things the government expects me to do.',
        author: {
            name: 'Sheryl Berge',
            role: 'CEO at Lynch LLC',
            image: Avatar,
        },
    },
    {
        content: 'I’m trying to get a hold of someone in support, I’m in a lot of trouble right now and they are saying it has something to do with my books. Please get back to me right away.',
        author: {
            name: 'Amy Hahn',
            role: 'Director at Velocity Industries',
            image: Avatar,
        },
    },
    {
        content: 'I’m trying to get a hold of someone in support, I’m in a lot of trouble right now and they are saying it has something to do with my books. Please get back to me right away.',
        author: {
            name: 'Amy Hahn',
            role: 'Director at Velocity Industries',
            image: Avatar,
        },
    },
    {
        content: 'I’m trying to get a hold of someone in support, I’m in a lot of trouble right now and they are saying it has something to do with my books. Please get back to me right away.',
        author: {
            name: 'Amy Hahn',
            role: 'Director at Velocity Industries',
            image: Avatar,
        },
    },
    {
        content: 'TaxPal is so easy to use I can’t help but wonder if it’s really doing the things the government expects me to do.',
        author: {
            name: 'Sheryl Berge',
            role: 'CEO at Lynch LLC',
            image: Avatar,
        },
    },
    {
        content: 'I’m trying to get a hold of someone in support, I’m in a lot of trouble right now and they are saying it has something to do with my books. Please get back to me right away.',
        author: {
            name: 'Amy Hahn',
            role: 'Director at Velocity Industries',
            image: Avatar,
        },
    },
]

export default function Reviews() {
    return (
        <section id="reviews" className="bg-slate-50 py-8 sm:py-20">
            <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="mx-auto max-w-2xl md:text-center">
                    <h2
                        id="reviews-title"
                        className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
                    >
                        Reviews
                    </h2>
                    <p className="mt-4 text-lg tracking-tight text-slate-700">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </div>
                <ul className="mx-auto mt-6 sm:mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3">
                    {reviews.map((review, columnIndex) => (
                        <li key={columnIndex}>
                            <figure className="relative h-full justify-between flex flex-col rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                                <svg
                                    aria-hidden="true"
                                    width={105}
                                    height={78}
                                    className="absolute top-6 left-6 fill-gray"
                                >
                                    <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
                                </svg>
                                <blockquote className="relative">
                                    <p className="text-base sm:text-lg tracking-tight text-slate-900">
                                        {review.content}
                                    </p>
                                </blockquote>
                                <figcaption className="relative mt-4 sm:mt-6 flex items-center justify-between border-t border-gray pt-4 sm:pt-6">
                                    <div>
                                        <div className="font-display text-base text-primary">
                                            {review.author.name}
                                        </div>
                                        <div className="mt-1 text-sm text-muted">
                                            {review.author.role}
                                        </div>
                                    </div>
                                    <div className="h-14 w-14 overflow-hidden rounded-full bg-slate-50">
                                        <img src={review.author.image} alt={review.author.name} />
                                    </div>
                                </figcaption>
                            </figure>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}