import Step from "../../components/step/Step";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import useStore from "../../storage/DataStore";

function Step1() {
    let navigate = useNavigate();

    const setGender = function (gender) {
        useStore.setState({
            gender: gender
        });

        navigate('../step2', { replace: true });
    }

    return (
        <Step currentStep={1}
            heading={<FormattedMessage id={'survey.step1.headline'} />}
            description={<FormattedMessage id={'survey.step1.description'} />}
            // StepImage="https://source.unsplash.com/fS3tGOkp0xY"
            StepVideo="https://iframe.videodelivery.net/10f42172f6f01db0c604528651e79144?preload=true"
            backLink={'/'}>
            <button onClick={() => setGender('male')}
                className={"block w-full text-left border rounded-lg shadow-sm px-6 py-4 cursor-pointer hover:ring-2 hover:ring-primary hover:border-primary focus:outline-none mb-4"}>
                <FormattedMessage id={'survey.step1.gender.male'} />
            </button>

            <button onClick={() => setGender('female')}
                className={"block w-full text-left border rounded-lg shadow-sm px-6 py-4 cursor-pointer hover:ring-2 hover:ring-primary hover:border-primary focus:outline-none mb-4"}>
                <FormattedMessage id={'survey.step1.gender.female'} />
            </button>
        </Step>
    );
}

export default Step1;
